import { Region } from 'scenarios/ScenarioType';
import { EnzymeModel, CompetitorEnzymeModel } from './EnzymeModel';
import LpHera from './LpHera';
import Termamyl2X from './Termamyl2X';
import LiquozymeSupra from './LiquozymeSupra';
import LiquozymeSupra22X from './LiquozymeSupra22X';
import LiquozymeSupra28X from './LiquozymeSupra28X';
import LiquozymeX from './LiquozymeX';
import LiquozymeX2X from './LiquozymeX2X';
import Competitor from './Competitor';
import SuhongAAPlus from './SuhongAAPlus';
import SuhongAAPlus2X from './SuhongAAPlus2X';
import LpHeraNeo from './LpHeraNeo';
import LpHeraDuo from './LpHeraDuo';

type Models = { [key: string]: EnzymeModel | CompetitorEnzymeModel };

const models: Models = {
  'Termamyl 2X': Termamyl2X,
  'LpHera': LpHera(false),
  'LpHera Neo': LpHeraNeo,
  'LpHera Duo': LpHeraDuo,
  'Liquozyme Supra': LiquozymeSupra,
  'Liquozyme Supra 2.2X': LiquozymeSupra22X,
  'Liquozyme Supra 2.8X': LiquozymeSupra28X,
  'Liquozyme X': LiquozymeX,
  'Liquozyme X 2X': LiquozymeX2X,
};

const getModels = (region?: Region): Models => {
  switch (region) {
    case Region.China:
      return {
        ...models,
        'LpHera Dx': LpHera(true),
        'Suhong AA Plus': SuhongAAPlus,
        'Suhong AA Plus 2X': SuhongAAPlus2X,
        Competitor,
      };
    default:
      return { ...models, Competitor };
  }
};

export default getModels;
