import { EnzymeInput } from './EnzymeInput';
import isEnzymeInputValid from './EnzymeInputValidator';

const intercept = -93.19312666;
const ca = -0.010308445;
const dose = 0.083471992;
const pH = 36.31954823;
const time = -0.06989112;
const pHpH = -3.587689901;
const timeTime = -0.000226743;
const timeDose = 0.000222934;
const timePH = 0.033021318;

/*
const pHGns = 4.8;
const doseGns = 57.51;
*/
const caGns = 20.0;

const calculation = (input: EnzymeInput, currentTime: number): number => {
  const cDosage = (input.dosage / 1000) * 1000 * 639;

  const result = 
    intercept +
    ca * caGns +
    dose * cDosage +
    pH * input.pH +
    time * currentTime +
    pHpH * input.pH ** 2 +
    timeTime * currentTime ** 2 +
    timeDose * currentTime * cDosage +
    timePH * currentTime * input.pH;
  return result;
};

const simulation = (deNeo: number[]): number[] => {
  // deNeo may not be the best/accurate variable name
  const res = [...deNeo];

  // Estimate DE at time 0
  res[0] = deNeo[1] - (deNeo[2] - deNeo[1]);

  return res;
};

const LpHeraDuo = (input: EnzymeInput): number[] => {
  if (!isEnzymeInputValid(input)) {
    return Array(9).fill(0);
  }

  const results: number[] = Array(9);
  const times: number[] = input.timeStep === 15 ? [0, 15, 30, 45, 60, 75, 90, 105, 120] : [0, 30, 60, 90, 120, 150, 180, 210, 240];

  for (let timeI = 0; timeI < 9; timeI += 1) {
    results[timeI] = calculation(input, times[timeI]);
  }
  return simulation(results);
};

export default LpHeraDuo;
