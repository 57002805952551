import { ScenarioDataKey, TimeStep } from './ScenarioType';

type Range = {
  min: number;
  max: number;
};

type EnzymeRanges = {
  dosage: Range;
  ph: Range;
  calcium: Range;
};

type EnzymeData = {
  [key: string]: EnzymeRanges;
};

export const ENZYME_RANGES_4H: EnzymeData = {
  'Termamyl 2X': {
    dosage: { min: 0.1, max: 0.25 },
    ph: { min: 5.9, max: 6.1 },
    calcium: { min: 40, max: 100 },
  },
  'LpHera': {
    dosage: { min: 0.1136, max: 0.2273 },
    ph: { min: 4.3, max: 5.9 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra': {
    dosage: { min: 0.16, max: 0.33 },
    ph: { min: 5.2, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra 2.2X': {
    dosage: { min: 0.0727, max: 0.15 },
    ph: { min: 5.2, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra 2.8X': {
    dosage: { min: 0.057, max: 0.12 },
    ph: { min: 5.2, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme X': {
    dosage: { min: 0.3, max: 0.42 },
    ph: { min: 4.9, max: 5.6 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme X 2X': {
    dosage: { min: 0.15, max: 0.21 },
    ph: { min: 4.9, max: 5.6 },
    calcium: { min: 5, max: 35 },
  },
  'Suhong AA Plus': {
    dosage: { min: 0.1, max: 0.25 },
    ph: { min: 5.2, max: 6.2 },
    calcium: { min: 0, max: 40 },
  },
  'Suhong AA Plus 2X': {
    dosage: { min: 0.05, max: 0.125 },
    ph: { min: 5.2, max: 6.2 },
    calcium: { min: 0, max: 40 },
  },
  'LpHera Dx': {
    dosage: { min: 0.1612, max: 0.3226 },
    ph: { min: 4.3, max: 5.9 },
    calcium: { min: 5, max: 35 },
  },
  // 'LpHera Neo': {
  //   dosage: { min: 0.07, max: 0.145 },
  //   ph: { min: 4.5, max: 5.4 },
  //   calcium: { min: 5, max: 35 },
  // },
};

export const ENZYME_RANGES_2H: EnzymeData = {
  'Termamyl 2X': {
    dosage: { min: 0.2, max: 0.4 },
    ph: { min: 5.8, max: 6.5 },
    calcium: { min: 20, max: 100 },
  },
  'LpHera': {
    dosage: { min: 0.1136, max: 0.2273 },
    ph: { min: 4.3, max: 5.9 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra': {
    dosage: { min: 0.22, max: 0.52 },
    ph: { min: 4.9, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra 2.2X': {
    dosage: { min: 0.1, max: 0.24 },
    ph: { min: 4.9, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme Supra 2.8X': {
    dosage: { min: 0.078, max: 0.186 },
    ph: { min: 4.9, max: 5.7 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme X': {
    dosage: { min: 0.3, max: 0.53 },
    ph: { min: 4.9, max: 5.6 },
    calcium: { min: 5, max: 35 },
  },
  'Liquozyme X 2X': {
    dosage: { min: 0.15, max: 0.27 },
    ph: { min: 4.9, max: 5.6 },
    calcium: { min: 5, max: 35 },
  },
  'Suhong AA Plus': {
    dosage: { min: 0.2, max: 0.4 },
    ph: { min: 5.2, max: 6.2 },
    calcium: { min: 0, max: 40 },
  },
  'Suhong AA Plus 2X': {
    dosage: { min: 0.1, max: 0.2 },
    ph: { min: 5.2, max: 6.2 },
    calcium: { min: 0, max: 40 },
  },
  'LpHera Dx': {
    dosage: { min: 0.1612, max: 0.3226 },
    ph: { min: 4.3, max: 5.9 },
    calcium: { min: 5, max: 35 },
  },
  'LpHera Neo': {
    dosage: { min: 0.07, max: 0.145 },
    ph: { min: 4.5, max: 5.4 },
    calcium: { min: 5, max: 30 },
  },
  'LpHera Duo': {
    dosage: { min: 0.068, max: 0.143 },
    ph: { min: 4.5, max: 5.4 },
    calcium: { min: 5, max: 35 },
  },
};

const dataKeyToRangeKey = (dataKey: ScenarioDataKey): keyof EnzymeRanges | undefined => {
  switch (dataKey) {
    case 'newEnzymeDosage':
    case 'refEnzymeDosage':
      return 'dosage';
    case 'newEnzymepH':
    case 'refEnzymepH':
      return 'ph';
    case 'newEnzymeCalcium':
    case 'refEnzymeCalcium':
      return 'calcium';
    default:
      return undefined;
  }
};

export const getRangesData = (timeStep?: TimeStep): EnzymeData => {
  return timeStep === 30 ? ENZYME_RANGES_4H : ENZYME_RANGES_2H;
};

const getEnzymeRange = (enzymeName: string, dataKey: ScenarioDataKey, timeStep: TimeStep = 15): Range | undefined => {
  const rangeKey = dataKeyToRangeKey(dataKey);
  if (!rangeKey) {
    return undefined;
  }
  const allRanges = getRangesData(timeStep);
  const rangesForEnzyme = allRanges[enzymeName];

  if (!rangesForEnzyme) {
    return undefined;
  }
  const ranges = rangesForEnzyme[rangeKey];

  return ranges;
};

export default getEnzymeRange;
